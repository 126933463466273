// @mui
import { styled } from "@mui/material/styles"
import React, { useState, useEffect, useRef } from "react"
import { Divider, Box, Grid, Typography, Container, Button, Card, IconButton, Input, Stack, Avatar, Tooltip } from "@mui/material"
// layouts
import Layout from "../layouts"
import useResponsive from "../hooks/useResponsive"
// components
import Page from "../components/Page"
import { NextSeo } from "next-seo"
import getSEOText from "../utils/getSEOText"
import styledComponent from "styled-components"
import Iconify from "src/components/Iconify"
import Link from "next/link"
// sections
import {
    HomeHero,
    HomeDiscover,
    HomeAdvertisement,
    HomeECommerceLogos,
    HomeInsightsSection,
    HomeHowWorks,
    HomeAutomatedGranular,
    HomeBlogSection,
    HomeMinimal,
    CapterraBadges,
    CustomEventsNewsletter,
    HomeImageScroller,
    HomeMinimalCopy,
    HomeAIRecommendations,
    HomeBackedBy,
    HomeGPTs,
    HomeDataSafety,
} from "../sections/home"
import { AboutTestimonials } from "../sections/about"
import { AvaSection } from "../sections/home"

// ----------------------------------------------------------------------

const RootStyle = styled("div")(() => ({
    height: "100%",
}))

const ContentStyle = styled("div")(({ theme }) => ({
    position: "relative",
    backgroundColor: theme.palette.background.default,
}))

// ----------------------------------------------------------------------

HomePage.getLayout = function getLayout(page) {
    return <Layout variant='main'>{page}</Layout>
}

// ------------------------------ ----------------------------------------

export async function getServerSideProps() {
    const res = await fetch(`https://posts.flowpoint.ai/wp-json/wp/v2/posts?status=publish&_embed`)
    const response = await res.json()
    const posts = response

    return {
        props: {
            posts,
        },
    }
}

export default function HomePage({ posts, isDesktop: isDesktopSSR }) {
    const isDesktop = useResponsive("up", "md")
    const issueContainerRef = useRef(null)

    useEffect(() => {
        const handleScroll = () => {
            if (issueContainerRef.current) {
                const scrollPercentage = window.scrollY / document.documentElement.scrollHeight
                issueContainerRef.current.scrollTop = scrollPercentage * issueContainerRef.current.scrollHeight
            }
        }

        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    return (
        <>
            <NextSeo
                title={getSEOText()?.title || ""}
                description={getSEOText()?.description || ""}
                canonical='https://flowpoint.ai'
                openGraph={{
                    url: "https://flowpoint.ai",
                    title: getSEOText()?.title,
                    description: getSEOText()?.description,
                    images: [
                        {
                            url: getSEOText()?.thumbnail,
                            width: 1920,
                            height: 1080,
                            alt: "Og Image Alt",
                            type: "image/jpeg",
                        },
                    ],
                    site_name: "Flowpoint",
                }}
                twitter={{
                    handle: "@handle",
                    site: "@site",
                    cardType: "summary_large_image",
                }}
            />
            <Page title={getSEOText()?.title}>
                <RootStyle>
                    <HomeHero
                        title='The workspace'
                        highlightedTitle={"for your website"}
                        subtitle='AI-powered website intelligence, optimization, and conversion tracking in one place.'
                    />
                    <ContentStyle>
                        <Divider orientation='vertical' sx={{ my: 5, mx: "auto", width: 1, height: 20 }} />
                        <HomeECommerceLogos />

                        <HomeMinimalCopy />
                        {/* <HomeGPTs /> */}
                        <HomeAutomatedGranular />
                        {isDesktop ? (
                            <HomeImageScroller
                                images={[
                                    "/images/features/1.webp",
                                    "/images/features/2.webp",
                                    "/images/features/3.webp",
                                    "/images/features/4.webp",
                                ]}
                                conversation={[
                                    {
                                        id: "1",
                                        messages: [
                                            {
                                                id: "m1",
                                                senderId: "user2",
                                                body: "Can you give me an overview of the traffic flow through our special offers section?",
                                                contentType: "text",
                                            },
                                            {
                                                id: "m2",
                                                senderId: "user1",
                                                body: "The 'Page Exploration' map shows that the special offers section is among the top three landing pages this month with over 4,000 visits. Interestingly, I've noticed that 60% of the traffic from this page goes directly to your product pages, indicating a high intent to purchase.",
                                                contentType: "text",
                                            },
                                        ],
                                    },
                                    {
                                        id: "2",
                                        messages: [
                                            {
                                                id: "m1",
                                                senderId: "user2",
                                                body: "Ava, can we optimize the user journey from our blog to the homepage to improve engagement?",
                                                contentType: "text",
                                            },
                                            {
                                                id: "m2",
                                                senderId: "user1",
                                                body: "It seems that a high portion of blog readers are not transitioning to your homepage. The data shows a disconnect due to a lack of clear calls-to-action directing readers to further explore your website.",
                                                contentType: "text",
                                            },
                                        ],
                                    },
                                    {
                                        id: "3",
                                        messages: [
                                            { id: "m1", senderId: "user2", body: "What issues are impactful?", contentType: "text" },
                                            {
                                                id: "m2",
                                                senderId: "user1",
                                                body: "A slow website can negatively impact user experience, leading to frustration and potentially decreasing the likelihood that a visitor will complete the action of clicking on the 'get started free' button.",
                                                contentType: "text",
                                            },
                                        ],
                                    },
                                    {
                                        id: "4",
                                        messages: [
                                            {
                                                id: "m1",
                                                senderId: "user2",
                                                body: "How can we increase the conversion between the flowpoints?",
                                                contentType: "text",
                                            },
                                            {
                                                id: "m2",
                                                senderId: "user1",
                                                body: "By strategically placing clear and compelling calls-to-action within your blog content, you could encourage a higher percentage of visitors to explore further. For instance, transitioning from a 4.53% to an 8% conversion rate is feasible with these adjustments.",
                                                contentType: "text",
                                            },
                                        ],
                                    },
                                ]}
                                texts={[
                                    {
                                        title: "Where do visitors go?",
                                        iconTitle: "DISCOVER",
                                        icon: "ph:graph",
                                        description:
                                            "See a bird's-eye view of your entire website traffic and identify the most common user journeys.",
                                    },
                                    {
                                        title: "Identifying issues at scale?",
                                        iconTitle: "SCALE",
                                        icon: "radix-icons:size",
                                        description:
                                            "See a comprehensive overview of your visitors' activities and quickly identify their pain points on a large scale within just a few minutes.",
                                    },
                                    {
                                        title: "What issues are impactful?",
                                        iconTitle: "PRIORITISE",
                                        icon: "mdi:podium-first",
                                        description:
                                            "Prioritize the most impactful areas for conversion rate optimization to efficiently allocate resources and boost results.",
                                    },
                                    {
                                        title: "What is the ROI?",
                                        iconTitle: "MEASURE",
                                        icon: "bx:stats",
                                        description:
                                            "Easily measure the return on investment and make data-driven decisions that grow your business.",
                                    },
                                ]}
                            />
                        ) : (
                            <HomeDiscover />
                        )}

                        <HomeAIRecommendations />
                        {/* <HomeDataSafety /> */}
                        <AvaSection />

                        <Divider orientation='vertical' sx={{ mb: { md: 3, xs: 2 }, mx: "auto", width: 1, height: 20 }} />
                        {/* <HomeInsightsSection /> */}
                        <AboutTestimonials />
                        {/* <HomeBackedBy /> */}
                        {/* <Divider orientation='vertical' sx={{ my: { md: 5, xs: 2 }, mx: "auto", width: 1, height: 20 }} /> */}

                        {/* <HomeDPO /> */}
                        {/* <Divider orientation='vertical' sx={{ my: { xs: 1, md: 5 }, mx: "auto", width: 1, height: 40 }} /> */}

                        <Divider orientation='vertical' sx={{ my: 5, mx: "auto", width: 1, height: 40 }} />
                        <HomeBlogSection />

                        <Divider orientation='vertical' sx={{ my: 5, mx: "auto", width: 1, height: 40 }} />
                        <HomeAdvertisement />
                        <Divider orientation='vertical' sx={{ my: 5, mx: "auto", width: 1, height: 40 }} />
                    </ContentStyle>
                </RootStyle>
            </Page>
        </>
    )
}
